export const VersionApp = '1.00.1';
// indicates status of an employee
// 0 => 'Deactivated',
// 1 => 'Activated',
// 2 => 'Deleted',


// 4 => 'Unverified'
export const EMPLOYEESTATUS = {
  DEACTIVATED: '0',
  ACTIVATED: '1',
  DELETED: '2',
  UNVERIFIED: '4',
};
export const DataCache = {
  ShiftTypes : [],
  ListReasons : [],
};

export const EMPLOYEEWORKINGSTATUS = {
  UNSCHEDULE: -1,
  WORKING: 0,
  NOTWORKING: 1,
  FINISHED: 2,
  LATEFORWORK: 3,
};

export const CLOCKINGPHASES = {
  NOPHASE: 0,
  CODE: 1,
  PASSWORD: 2,
  CLOCKING: 3,
};

export const CLOCKINGTYPES = {
  CLOCKIN: 'CLOCK_IN',
  STARTBREAK: 'START_BREAK',
  ENDBREAK: 'END_BREAK',
  CLOCKOUT: 'CLOCK_OUT',
};
export const LOCALSTORAGE_BACKURL = 'LSBU';
export const LOCALSTORAGE_LastOpenRegistrationTime = 'LastOpenRegistrationTime';
export const LOCALSTORAGE_CURRENTCOMPANY = 'LSCC';
export const LOCALSTORAGE_COMPANYDATA = 'LSCD';
export const LOCALSTORAGE_CURRENTEMPLOYEE = 'LSCE';
export const LOCALSTORAGE_COMPANYNAME = 'LSCN';
export const LOCALSTORAGE_CLOCKINGSUCCESS = 'LSCS';
export const LOCALSTORAGE_CURRENTDWORKPLACE = 'LSCW';
export const LOCALSTORAGE_EMPLOYEESLIST = 'LSEL';
export const LOCALSTORAGE_LANGUAGES = 'locale';
export const LOCALSTORAGE_SHOWDEPARTMENTS = 'LSSD';
export const LOCALSTORAGE_SHOWGROUPS = 'LSSG';
export const LOCALSTORAGE_VERSIONCONTROL = 'LSVC'
export const LOCALSTORAGE_WORKPLACENAME = 'LSWN';
export const LOCALSTORAGE_COSTCENTER = 'LSWN';
export const LOCALSTORAGE_SHIFTYPES = 'LSST';

export const COOKIE_EMPLOYEETOKEN = 'CKCE';
export const COOKIE_SHOWDEPARTMENTS = 'CKSD';
export const COOKIE_SHOWGROUPS = 'CKSG';
export const COOKIE_LANGUAGES = 'lang';
