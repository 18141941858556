import React, { Component } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';

export interface AppContextInterface {
  auth: any;
  authenticated: boolean;
  currentTimeZone: string;
  currentDateFormat: string;
  intl?: {
    locale?: string;
    formatMessage?: (...params: any) => any;
    formatRelative?: (...params: any) => any;
    formatNumber?: (...params: any) => any;
    formatPlural?: (...params: any) => any;
    formatDate?: (...params: any) => any;
    formatTime?: (...params: any) => any;
  };
  companyInformation: any;
  employeeListing: {
    plannings: Array<any>;
    timesheets: Array<any>;
    employees: Array<any>;
  };
  companies: Array<any>;
  workplaces: Array<any>;
  companyLoaded: boolean;
  currentPickedCompanyId: string;
  currentPickedWorkplaceId: string;
  onUpdateContext: (...params: any) => void;
}

export const AppContext = React.createContext<AppContextInterface>({
  auth: null,
  authenticated: false,
  companyInformation: null,
  currentDateFormat: null,
  employeeListing: {
    plannings: [],
    timesheets: [],
    employees: [],
  },
  companies: [],
  workplaces: [],
  companyLoaded: false,
  currentTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  currentPickedCompanyId: '',
  currentPickedWorkplaceId: '',
  onUpdateContext: context => context,
});

export class AppProvider extends Component<InjectedIntlProps> {
  onUpdateContext = context => {
    const newContext = { ...this.state, ...context };
    this.setState(newContext);
  };

  state = {
    auth: null,
    authenticated: false,
    companyInformation: null,
    employeeListing: {
      plannings: [],
      timesheets: [],
      employees: [],
    },
    companies: [],
    workplaces: [],
    currentPickedCompanyId: '',
    currentPickedWorkplaceId: '',
    companyLoaded: false,
    currentDateFormat: null,
    currentTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    intl: {
      locale: this.props.intl.locale,
      formatMessage: this.props.intl.formatMessage,
      formatRelative: this.props.intl.formatRelative,
      formatNumber: this.props.intl.formatNumber,
      formatPlural: this.props.intl.formatPlural,
      formatDate: this.props.intl.formatDate,
      formatTime: this.props.intl.formatTime,
    },
    onUpdateContext: this.onUpdateContext,
  };

  render() {
    return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
  }
}

export default injectIntl<{ intl?: any }>(AppProvider);
