import { addLocaleData } from 'react-intl';
import moment from 'moment';
import { LOCALSTORAGE_CURRENTCOMPANY, LOCALSTORAGE_LANGUAGES } from './assets/constants/contants';

export const languages = {
  en: {
    label: 'labels.english',
    intlCode: 'en',
    momentLocale: 'en',
  },
  vi: {
    label: 'labels.vietnamese',
    intlCode: 'vi',
    momentLocale: 'vi',
  },
  da: {
    label: 'labels.danish',
    intlCode: 'da',
    momentLocale: 'da',
  },
  ru: {
    label: 'labels.russian',
    intlCode: 'ru',
    momentLocale: 'ru',
  },
  fr: {
    label: 'labels.french',
    intlCode: 'fr',
    momentLocale: 'fr',
  },
  'zh-CN': {
    label: 'labels.chinese',
    intlCode: 'zh',
    momentLocale: 'zh-cn',
  },
  de: {
    label: 'labels.german',
    intlCode: 'de',
    momentLocale: 'de',
  },
  ja: {
    label: 'labels.japanese',
    intlCode: 'ja',
    momentLocale: 'ja',
  },
  id: {
    label: 'labels.indonesian',
    intlCode: 'id',
    momentLocale: 'id',
  },
  es: {
    label: 'labels.spanish',
    intlCode: 'es',
    momentLocale: 'es',
  },
  hi: {
    label: 'labels.hindi',
    intlCode: 'hi',
    momentLocale: 'hi',
  },
  ar: {
    label: 'labels.arabic',
    intlCode: 'ar',
    momentLocale: 'ar',
  },
  pt: {
    label: 'labels.portuguese',
    intlCode: 'pt',
    momentLocale: 'pt',
  },
  bn: {
    label: 'labels.bengali',
    intlCode: 'bn',
    momentLocale: 'bn',
  },
};

export const getTranslationMessages = async () => {
  const data = {};
  const currentCompany = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENTCOMPANY));
  const companyId = currentCompany ? currentCompany.company_id : '';
  const cookieLang = localStorage.getItem(`${LOCALSTORAGE_LANGUAGES}${companyId}`);
  // const cookieLang = Cookies.get(`${COOKIE_LANGUAGES}${companyId}`);
  const langCode = cookieLang || 'en';

  const currentLanguage = languages[langCode];
  const module = await import(`react-intl/locale-data/${currentLanguage.intlCode}`);

  if (currentLanguage.momentLocale !== 'en') {
    await import(`moment/locale/${currentLanguage.momentLocale}`);
    moment.locale(currentLanguage.momentLocale);
  }
  addLocaleData(module.default);

  const translationMessageModule = await import(`@shared/translations/${langCode}.json`);
  data[langCode] = translationMessageModule.default;
  return data;
};
