const theme = {
  colors: {
    primary: '#366a22',
    blackGrey: '#484848',
    red: '#eb3b5a',
    blue: '#45aaf2',
    purple: '#c42de3',
    orange: '#fd9644',
    yellow: '#f7b731',
    green: '#26de81',
    grey: '#777777',
    blueGreen: '#2bcbba',
    lightGrey: '#eeeeee',
  },
  mobile: {
    fontFamily: {
      light: 'CircularLight',
      normal: 'CircularBook',
      medium: 'CircularMedium',
      bold: 'CircularBold',
      black: 'CircularBlack',
    },
  },
};

export const createTheme = (platform: 'ios' | 'android' | 'macos' | 'windows' | 'web') => {
  if (platform === 'ios') {
    return {
      ...theme,
      ...{},
    }
  }
  if (platform === 'android') {
    return {
      ...theme,
      ...{
        mobile: {
          fontFamily: {
            light: 'sans-serif-light',
            normal: 'sans-serif',
            medium: 'sans-serif-black',
            bold: 'sans-serif-black',
            black: 'sans-serif-medium',
          },
        },
      },
    }
  }
  return theme;
};

export default theme;
