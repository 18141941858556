import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Homepage from './containers/HomePage/Homepage';
const ForbiddenPage = React.lazy(() => import('./containers/ErrorPages/ForbiddenPage'));
// const SingleCompany = React.lazy(() => import('./containers/SingleCompany/SingleCompany'));
// const EmployeeVerify = React.lazy(() => import('./containers/EmployeeVerify/EmployeeVerify'));
// const EmployeeCodeVerification = React.lazy(() => import('./components/EmployeeVerify/EmployeeCodeVerification'));
// const PasswordVerification = React.lazy(() => import('./containers/EmployeeVerify/PasswordVerification'));
// const ClockInClockOut = React.lazy(() => import('./containers/ClockInClockOut/ClockInClockOut'));
const SingleWorkplace = React.lazy(() => import('./containers/SingleWorkplace/SingleWorkplace'));
const InternalServerErrorPage = React.lazy(() => import('./containers/ErrorPages/InternalServerErrorPage'));
const NotFoundPage = React.lazy(() => import('./containers/ErrorPages/NotFoundPage'));

const StyledPageLoading = styled.div`
  min-height: calc(100vh - (180px + 100px));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h5 {
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.grey};
  }
`;

const PageLoading = () => (
  <StyledPageLoading>
    <h5>Loading...</h5>
  </StyledPageLoading>
);

export default function renderRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/company/:companyId">
        <React.Suspense fallback={<PageLoading />}>
          {/* <EmployeeCodeVerification /> */}
          <SingleWorkplace />
        </React.Suspense>
      </Route>
      {/* <Route exact path="/company/:companyId/verify/:employeeId">
        <React.Suspense fallback={<PageLoading />}>
          <PasswordVerification />
        </React.Suspense>
      </Route>
      <Route exact path="/company/:companyId/clocking/:employeeId">
        <React.Suspense fallback={<PageLoading />}>
          <ClockInClockOut />
        </React.Suspense>
      </Route> */}
      <Route exact path="/403">
        <React.Suspense fallback={<PageLoading />}>
          <ForbiddenPage />
        </React.Suspense>
      </Route>
      <Route exact path="/500">
        <React.Suspense fallback={<PageLoading />}>
          <InternalServerErrorPage />
        </React.Suspense>
      </Route>
      <Route exact path="*">
        <React.Suspense fallback={<PageLoading />}>
          <NotFoundPage />
        </React.Suspense>
      </Route>
    </Switch>
  );
}
