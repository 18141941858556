import { LOCALSTORAGE_CURRENTCOMPANY } from '../../src/assets/constants/contants';

require('es6-promise').polyfill();

class Fetch {
  protected headers: HeadersInit;
  protected loading: boolean = false;
  protected endPoint: string;

  constructor() {
    this.endPoint = process.env.API_URL;
    // this.endPoint = 'https://apidev.titanplan.com/api/';
    // this.endPoint = 'https://apiqa.titanplan.com/api/';
  }
  setEndPoint(apiUrl:string){
    if(apiUrl){
      this.endPoint = apiUrl;
    }
  }
  buildHeaders(): HeadersInit {
    const thisCompany = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENTCOMPANY));
    if (thisCompany && thisCompany.url_api) {
      if (thisCompany.url_api != this.endPoint) {
        this.endPoint = thisCompany.url_api;
      }
    }
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: thisCompany ? thisCompany.token : '',
    };
    return this.headers;
  }

  static processResponse(response: Response) {
    if (response.status === 401) {
      alert('Your session has expired, please login to continue!');
      return (location.href = '/');
    }
    if (response.status === 500) {
      throw new Error('Failed to fetch!');
    }
    return true;
  }

  setURL() {
    const thisCompany = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENTCOMPANY));
    if (thisCompany && thisCompany.url_api) {
      if (thisCompany.url_api != this.endPoint) {
        this.endPoint = thisCompany.url_api;
      }
    }else {
      this.endPoint = process.env.API_URL;
    }
  }

  get(url: string, headers: any = {}) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'GET',
      headers: { ...this.buildHeaders(), ...headers },
    })
      .then(res => {
        Fetch.processResponse(res);
        return res;
      })
      .then(res => res.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .catch(error => console.warn(`${url} with error: ${error.message}`));
  }

  post(url: string, data: any = {}, headers: any = {}) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'POST',
      headers: { ...this.buildHeaders(), ...headers },
      body: JSON.stringify(data),
    })
      .then(res => {
        Fetch.processResponse(res);
        return res;
      })
      .then(res => res.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .catch(error => console.warn(`${url} with error: ${error.message}`));
  }

  postFormData(url: string, data: any = {}, headers: any = {}) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'POST',
      headers: { ...headers },
      body: data,
    })
      .then(res => {
        Fetch.processResponse(res);
        return res;
      })
      .then(res => res.text())
      .then(text => (text.length ? JSON.parse(text) : {}))
      .catch(error => console.warn(`${url} with error: ${error.message}`));
  }

  put(url: string, data: any = {}, headers: any = {}) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'PUT',
      headers: { ...this.buildHeaders(), ...headers },
      body: JSON.stringify(data),
    }).then((response: Response) => {
      Fetch.processResponse(response);
      return response.json();
    });
  }

  delete(url: string, body?: any) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'DELETE',
      headers: this.buildHeaders(),
      body: body ? JSON.stringify(body) : null,
    }).then((response: Response) => {
      Fetch.processResponse(response);
      return response.json();
    });
  }

  async getMyIp() {
    try {
      const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
      const results = await response.text();
      const resultsArray = results.split('\n');
      const ip = resultsArray.find(ip => ip.indexOf('ip=') > -1);
      localStorage.setItem('ip', ip.toString().slice(3, ip.length));
      return ip.toString().slice(3, ip.length);
    } catch (error) {
      console.log(error.message);
      return '';
    }
  }
}

export default new Fetch();
