import React from 'react';
import { AppContext, AppContextInterface } from '../contexts/AppContext';

export class BaseComponentWithContext<P = {}, S = {}, SS = any> extends React.PureComponent<P, S, SS> {
  static contextType = AppContext;
  context: AppContextInterface;

  renderHeaderSpacerSmall = () => {
    return (
      <div style={{ marginTop: 70 }} />
    );
  };

  renderHeaderSpacer = () => {
    return (
      <div style={{ marginTop: 100 }} />
    );
  };
}

BaseComponentWithContext.contextType = AppContext;

export default BaseComponentWithContext;
