import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { notification, Button } from 'antd';
import { Fetcher } from '@shared/core';
import { LOCALSTORAGE_VERSIONCONTROL } from '../../assets/constants/contants';

const StyledRefreshButton = styled(Button)`
  margin-top: 20px;
  /* height: 32px; */
  font-size: 20px;
`;

export default () => {
  const [versionFromAPI, setVersionFromAPI] = useState('');
  const [shouldRefreshForNewVersion, setShouldRefreshForNewVersion] = useState(false);

  useEffect(() => {
    const fetchVersion = async () => {
      var versionResponse = await fetch(`${process.env.API_URL}company/getSystemSetting/version_clock`, {
        method: 'GET',
        headers: Fetcher.buildHeaders() ,
      }).then(res => {
          return res;
        })
        .then(res => res.text())
        .then(text => (text.length ? JSON.parse(text) : {}));
      if (versionResponse) {
        const newestVersionFromAPI = versionResponse.value;

        let currentVersion = localStorage.getItem(LOCALSTORAGE_VERSIONCONTROL);
        if (currentVersion === undefined) {
          currentVersion = ''
        }
        if (!currentVersion || currentVersion !== newestVersionFromAPI) {
          setVersionFromAPI(newestVersionFromAPI);
          localStorage.setItem(LOCALSTORAGE_VERSIONCONTROL, newestVersionFromAPI);
          setShouldRefreshForNewVersion(true);
        }
      }
    };
    fetchVersion();
  }, []);

  useEffect(() => {
    if (shouldRefreshForNewVersion) {
      openNotification();
    } else {
      notification.destroy();
    }
  });

  const handleOnClickRefreshPage = () => {
    if (shouldRefreshForNewVersion && versionFromAPI !== '') {
      localStorage.setItem(LOCALSTORAGE_VERSIONCONTROL, versionFromAPI);
    }
    window.location.reload();
  };

  const openNotification = () => {
    notification.destroy();
    notification.warning({
      message: 'New version of Web Timeclock is detected. Please refresh the service!',
      description: (
        <StyledRefreshButton type="primary" size="small" onClick={handleOnClickRefreshPage}>
          refresh now
        </StyledRefreshButton>
      ),
      placement: 'bottomRight',
      duration: 0,
    });
  };

  return <></>;
};
