import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Select } from 'antd';
import {
  LOCALSTORAGE_CURRENTCOMPANY,
  LOCALSTORAGE_SHOWDEPARTMENTS,
  LOCALSTORAGE_SHOWGROUPS,
  LOCALSTORAGE_LANGUAGES,
} from '../../../assets/constants/contants';

const { Option } = Select;

const StyledModal = styled(Modal)`
  width: 500px;
`;

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default ({ isOpen, onClose }: ModalProps) => {
  const currentCompany = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENTCOMPANY));
  const currentCompanyId = currentCompany['company_id'];
  const deparments = localStorage.getItem(`${LOCALSTORAGE_SHOWDEPARTMENTS}${currentCompanyId}`);

  const [department, setDepartment] = useState<string[]>(deparments ? JSON.parse(deparments) : []);
  const [group, setGroup] = useState(localStorage.getItem(`${LOCALSTORAGE_SHOWGROUPS}${currentCompanyId}`) || '-1');
  const [lang, setLang] = useState(localStorage.getItem(`${LOCALSTORAGE_LANGUAGES}${currentCompanyId}`) || 'en');

  const onChangeDepartment = (departmentIds: string[]) => {
    setDepartment(departmentIds)
    setGroup('-1');
  };
  const onChangeLanguage = (key: string) => setLang(key);

  const onSubmit = () => {
    localStorage.setItem(`${LOCALSTORAGE_SHOWDEPARTMENTS}${currentCompanyId}`, JSON.stringify(department));
    localStorage.setItem(`${LOCALSTORAGE_LANGUAGES}${currentCompanyId}`, lang);
    window.location.reload(true);
    onClose();
  };

  const { departments } = currentCompany;
  const listOfDepartments = departments.map(departmentItem => (
    <Option key={departmentItem['department_id']} value={departmentItem['department_id']}>
      {departmentItem['department_name']}
    </Option>
  ));

  return (
    <StyledModal
      className="testModal"
      title="Settings"
      visible={isOpen}
      width="350px"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button className="ant-btn" key="submit" type="primary" onClick={onSubmit}>
          Save
        </Button>,
      ]}
    >
      <div>
        <p className="labelText">Show departments</p>
        <Select defaultValue={department} onChange={onChangeDepartment} mode="multiple">
          {listOfDepartments}
        </Select>

        <p className="labelText">Languages</p>
        <Select defaultValue={lang} onChange={onChangeLanguage}>
          <Option value="en">English</Option>
          <Option value="da">Danish</Option>
          <Option value="vi">Vietnamese</Option>
        </Select>
      </div>
    </StyledModal>
  );
};
