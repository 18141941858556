import {
  LOCALSTORAGE_BACKURL,
  LOCALSTORAGE_CURRENTCOMPANY,
  LOCALSTORAGE_COMPANYDATA,
  LOCALSTORAGE_CURRENTEMPLOYEE,
  LOCALSTORAGE_COMPANYNAME,
  LOCALSTORAGE_CLOCKINGSUCCESS,
  LOCALSTORAGE_CURRENTDWORKPLACE,
  LOCALSTORAGE_EMPLOYEESLIST,
  LOCALSTORAGE_WORKPLACENAME,
} from '../assets/constants/contants';
import moment from 'moment-timezone';
import { Fetcher } from '@shared/core';

export async function  setCurrentCompany(companyId) {
  const companies = JSON.parse(localStorage.getItem(LOCALSTORAGE_COMPANYDATA));
  const company = companies.find(c => c.company_id.toString() === companyId.toString());
  Fetcher.setEndPoint(company.url_api);
 let res = await Fetcher.get('clockapp/detail-clock-app', {
    Token: company.token,
  });
  if (res) {
    res.url_api = company.url_api;
    localStorage.setItem(LOCALSTORAGE_CURRENTCOMPANY, JSON.stringify(res));
    localStorage.setItem(LOCALSTORAGE_COMPANYNAME, company.company_name);
    moment.tz.setDefault(company.time_zone);
  }
}

export function setCurrentEmployee(employeeId) {
  const employees = JSON.parse(localStorage.getItem(LOCALSTORAGE_EMPLOYEESLIST));
  const thisEmployee = employees.find(employeeItem => employeeItem.employee_id.toString() === employeeId.toString());
  if (thisEmployee) {
    localStorage.setItem(LOCALSTORAGE_CURRENTEMPLOYEE, JSON.stringify(thisEmployee));
  }
}

export function clearAppLocalStorage() {
  localStorage.removeItem(LOCALSTORAGE_BACKURL);
  localStorage.removeItem(LOCALSTORAGE_CLOCKINGSUCCESS);
  localStorage.removeItem(LOCALSTORAGE_COMPANYNAME);
  localStorage.removeItem(LOCALSTORAGE_CURRENTEMPLOYEE);
  localStorage.removeItem(LOCALSTORAGE_EMPLOYEESLIST);
  localStorage.removeItem(LOCALSTORAGE_WORKPLACENAME);
  localStorage.removeItem(LOCALSTORAGE_CURRENTDWORKPLACE);
  localStorage.removeItem(LOCALSTORAGE_CURRENTCOMPANY);
  localStorage.removeItem(LOCALSTORAGE_COMPANYDATA);
  localStorage.removeItem('CompanyToken');
  localStorage.removeItem('UserToken');
}

export function clearUserPageLocalStorage() {
  localStorage.removeItem(LOCALSTORAGE_CURRENTCOMPANY);
  localStorage.removeItem(LOCALSTORAGE_EMPLOYEESLIST);
}
