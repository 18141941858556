import React from 'react';
import Helmet from 'react-helmet';
import Skeleton from 'antd/lib/skeleton';
import styled from 'styled-components';
import BaseComponentWithContext from '@shared/components/BaseComponentWithContext';
import { RouterHistoryService } from '@shared/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NotFoundImage from '../../assets/images/not-found.svg';
import { setCurrentCompany } from '../../utils/localStorageHelper';
import {
  LOCALSTORAGE_COMPANYNAME,
  LOCALSTORAGE_WORKPLACENAME,
  LOCALSTORAGE_COMPANYDATA,
  LOCALSTORAGE_CURRENTCOMPANY
} from '../../assets/constants/contants';

interface HomepageStates {
  // loading: boolean;
  companies: Array<any>;
}

interface HomepageProps extends RouteComponentProps<any> { }

const StyledHomepage = styled.div`
  .row-items {
    .ant-row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
`;

const StyledRow = styled.div``;

const StyledSelectCompanyText = styled.h1`
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #7d7d7d;
  margin-bottom: 2.5rem;
`;

const StyledCompanyCard = styled.div`
  width: 280px;
  height: calc(100% - 2rem);
  padding: 20px 20px 40px;
  text-align: center;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07), 0px -1px 4px rgba(1, 0, 0, 0.05);
  transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
  &:hover {
    box-shadow: 0 0 13px 0 #87ceeb;
  }
  .ant-card-body {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  ul {
    padding: 0;
    margin-bottom: 10px;
  }
  p {
    position: absolute;
    font-weight: 500;
    text-align: center;
    bottom: 0;
    margin-bottom: 0;
    font-size: 16px;
    text-transform: uppercase;
  }
`;

const StyledCompanyNotFound = styled.div`
  min-height: 200px;
  background: transparent;
  max-width: 540px;
  margin: 4rem auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: center;
    color: ${props => props.theme.colors.blackGrey};
  }
  img {
    width: 120px;
  }
  a {
    font-size: 16px;
  }
`;

const StyledCard = styled.div`
  width: 280px;
  height: calc(100% - 2rem);
  padding: 20px 20px;
  text-align: center;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07), 0px -1px 4px rgba(1, 0, 0, 0.05);
  transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    display: inline-block;
  }
  h4 {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    margin: 1rem 0 0;
  }
`;

const CompanyLoading = () => (
  <StyledCompanyCard>
    <Skeleton title={null} active={true} paragraph={{ rows: 4 }} />
  </StyledCompanyCard>
);
const CompanyItem = ({ company, onSelectCompany }) => (
  <StyledCard onClick={() => onSelectCompany(company)}>
    <img src={company.logo_url} alt={company.company_name} />
    <h4> {company.company_name} </h4>
  </StyledCard>
);
const ip = localStorage.getItem('ip');
class Homepage extends BaseComponentWithContext<HomepageProps, HomepageStates> {
  constructor(props) {
    super(props);
    this.state = {
      companies: JSON.parse(localStorage.getItem(LOCALSTORAGE_COMPANYDATA)),
    };
  }
  componentDidMount() {
    localStorage.removeItem(LOCALSTORAGE_COMPANYNAME);
    localStorage.removeItem(LOCALSTORAGE_WORKPLACENAME);

    this.oneCompanyThenRedirect();
  }

  componentDidUpdate() {
    this.oneCompanyThenRedirect();
  }

  oneCompanyThenRedirect = () => {
    if (this.state.companies.length === 1) {
      requestAnimationFrame(() => {
        this.onSelectCompany(this.state.companies[0]);
      });
    }
  };

  onSelectCompany = async (company: any) => {
    await  setCurrentCompany(company.company_id);
    RouterHistoryService.push(`/company/${company.company_id}`);
  };
  getNoCompanyFoundMessage = (formatMessage: any) => {
    let textCamera = formatMessage({ id: 'labels.noCompanyFoundMessage' });
    if (window.location.href.indexOf('hiloplan') >= 0) {
      textCamera = formatMessage({ id: 'labels.noCompanyFoundMessageHiloplan' });
    }
    return textCamera;
  }
  render() {
    // const { companies, companyLoaded } = this.context;
    // const companies = JSON.parse(localStorage.getItem(LOCALSTORAGE_COMPANYDATA));
    const { companies } = this.state;
    const companyLoaded = localStorage.getItem('CompanyLoaded');
    const { formatMessage } = this.context.intl;

    const companyListNotEmpty = companyLoaded === 'true' && companies.length > 0;

    return (
      <React.Fragment>
        <Helmet title="Select company" />
        <StyledHomepage>
          <StyledRow>
            {companyListNotEmpty && <StyledSelectCompanyText>SELECT YOUR COMPANY</StyledSelectCompanyText>}
          </StyledRow>
          <StyledRow>
            {!companyLoaded && (
              <div className="row justify-content-center no-margins">
                <div className="col-auto">
                  <CompanyLoading />
                </div>
                <div className="col-auto">
                  <CompanyLoading />
                </div>
              </div>
            )}
          </StyledRow>
          <StyledRow>
            {companyListNotEmpty && (
              <div className="row justify-content-center no-margins">
                {companies.map((item, i) => {
                  return (
                    <div className="col-auto" key={`company-${i}`}>
                      <CompanyItem onSelectCompany={this.onSelectCompany} company={item} />
                    </div>
                  );
                })}
              </div>
            )}

            {!companyListNotEmpty && (
              <StyledCompanyNotFound>
                <img src={NotFoundImage} width={'30%'} alt="" />
                <p>
                  {formatMessage({ id: 'labels.ip' })} {ip} <br />
                  {this.getNoCompanyFoundMessage(formatMessage)}
                </p>
                {/* <a href={'https://titanplan.com'}>{formatMessage({ id: 'labels.backToTitanPlan' })}</a> */}
              </StyledCompanyNotFound>
            )}
          </StyledRow>
        </StyledHomepage>
      </React.Fragment>
    );
  }
}

const HomepageWrapper: any = Homepage;
export default Object.assign(withRouter(HomepageWrapper), { contextType: undefined });
