import React from 'react';
import { IntlProvider } from 'react-intl';

interface LanguageProviderProps {
  messages?: Array<any> | any;
  localStorageLanguageString: string;
}

export class LanguageProvider extends React.PureComponent<LanguageProviderProps> {
  state = {
    locale: localStorage.getItem(this.props.localStorageLanguageString) || 'en',
  };

  render() {
    return (
      <IntlProvider
        locale={this.state.locale}
        key={this.state.locale}
        messages={this.props.messages[this.state.locale]}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

export default LanguageProvider;
